<template>
  <Box>
    <Row>
     <Column :width="3"/>
     <Column :width="9">
       <SpacerRow />
    
        <div class="rc-row">
          <div class="rc-box-col-3"></div>
          <div class="rc-box-col-9 rc-text-centre rc-text-xxlarge rc-text-label">
          {{ C.get(CatalogKeys.SIGN.UP.TITLE) }} 
          </div>
          <div class="rc-box-col-3"></div>
        </div>
    
        
        <LabelRow
          styling="rc-text-medium rc-text-value"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :centre="true"
          label="Free trial with no credit card required!"/>
    
        <SpacerRow />
    
        <LabelRow
          styling="rc-text-xlarge rc-text-label"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :centre="true"
          label="Welcome to the ReadyChek Registration Page!"/>
        
    
        <LabelRow
          styling="rc-text-small rc-text-value"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="ReadyChek is more than just an inspection app. It is designed to help owner/operators manage the pre-trip inspections and/or hours of service records for their drivers.  The solution includes a web portal for owners and managers to add/update/remove vehicles, trailers and users, and also to track inspections and hours of service for all users.  Drivers use the app to create and update inspections, and track their hours of service if that option is activated." />
    
        <!--
        <LabelRow
          styling="rc-text-small rc-text-value"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="Registrations should be on behalf of a company and should be done by a person with the authority to make such decisions." />
        -->
        
        <LabelRow
          styling="rc-text-small rc-text-value"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="Please fill in the form below with your contact details and a little information about your company and you will receive your welcome email containing your temporary password and set up instructions in the next 24 hours." />
    
        <ErrorRow 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :error="error" />
        
        <SuccessRow 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :message="message" />
        
        <SpacerRow />
        
        <!-- User -->
        <TitleRow :left="LEFT_SIDE" :right="RIGHT_SIDE">{{ C.get(CatalogKeys.SIGN.UP.USER_TITLE) }} </TitleRow>
        
        <Row>
          <div class="rc-box-col-7">
            <div class="container">
              <KeyValue 
                :mandatory="true"
                :required="isRequired(SignUpFields.FIRST)"
                :title="SignUpFields.FIRST" 
                :disabled="isDisabled" 
                :placeholder="CatalogKeys.SIGN.UP.FIRST"
                v-on:value="setValue"/>
            </div>
          </div>
          
          <div class="rc-box-col-8">
            <div class="rc-container">
              <KeyValue
                :mandatory="true"
                :required="isRequired(SignUpFields.LAST)"
                :title="SignUpFields.LAST"
                :disabled="isDisabled" 
                :placeholder="CatalogKeys.SIGN.UP.LAST" 
                v-on:value="setValue"/>
            </div>
          </div>
        </Row>
        
        <Row>
          <div class="rc-box-col-7">
            <div class="rc-container">
              <KeyEmail
                :mandatory="true"
                :required="isRequired(SignUpFields.EMAIL)"
                :variable="SignUpFields.EMAIL" 
                :title="SignUpFields.EMAIL"
                :disabled="isDisabled"
                :placeholder="CatalogKeys.SIGN.UP.EMAIL"
                v-on:value="setValue"/>
            </div>
          </div>
          
          <div class="rc-box-col-8">
            <div class="rc-container">
              <KeyPhone
                :mandatory="true"
                :required="isRequired(SignUpFields.PHONE)"
                :title="SignUpFields.PHONE"
                :disabled="isDisabled" 
                :placeholder="CatalogKeys.SIGN.UP.PHONE" 
                v-on:value="setValue"/>
            </div>
          </div>
        </Row>
      
        
        <SpacerRow />
        
        <!-- Company -->
        <TitleRow :left="LEFT_SIDE" :right="RIGHT_SIDE">{{ C.get(CatalogKeys.SIGN.UP.COMPANY_TITLE) }}</TitleRow>
        
        <div class="rc-row">
          <div class="rc-box-col-7">
            <div class="container">
              <KeyValue
                :required="isRequired(SignUpFields.COMPANY_NAME)"
                :title="SignUpFields.COMPANY_NAME"
                :mandatory="true"
                :disabled="isDisabled" 
                :placeholder="CatalogKeys.SIGN.UP.COMPANY" 
                v-on:value="setValue"/>
            </div>
          </div>
          
          <div class="rc-box-col-8">
            <div class="rc-container">
              <KeyValue 
                :mandatory="true"
                :required="isRequired(SignUpFields.COMPANY_CITY)"
                :title="SignUpFields.COMPANY_CITY"
                :disabled="isDisabled" 
                :placeholder="CatalogKeys.SIGN.UP.CITY" 
                v-on:value="setValue"/>
            </div>
          </div>
          
        </div>
      
        <div class="rc-row">
          <div class="rc-box-col-7">
            <div class="container">
              <KeyList 
                :mandatory="true"
                :required="isRequired(SignUpFields.COMPANY_PROVINCE)"
                :title="SignUpFields.COMPANY_PROVINCE"
                :disabled="isDisabled" 
                :options="provinces"
                :placeholder="CatalogKeys.SIGN.UP.PROVINCE"
                v-on:value="setProvinceValue"/>
            </div>
          </div>
          
          <div class="rc-box-col-8">
            <div class="rc-container">
              <KeyList 
                :mandatory="true"
                :title="SignUpFields.ROLE" 
                :required="isRequired(SignUpFields.ROLE)"
                :disabled="isDisabled" 
                :options="roles"
                :placeholder="CatalogKeys.SIGN.UP.ROLE" 
                v-on:value="setRoleValue"/>
            </div>
          </div>
        </div>
    
        <TitleRow
          v-if="isAmerican"
          type="warning" :left="LEFT_SIDE" :right="RIGHT_SIDE">Attention Neighbour!</TitleRow>
    
        <div class="rc-row" v-if="isAmerican">
          <div class="rc-box-col-4" />
          <div class="rc-box-col-7 rc-text-value rc-text-small">
            ReadyChek is currently designed for Canada but we do welcome accounts from 
            the US. Athought we both need to do a pre-trip vehicle inspection, in Canada 
            it must be recorded as proof of inspection. The Generic Vehicle Schedule in the 
            app should be selected when adding vehicles and trailers.
            If you have any questions, please contact 
            <a href="mailto:support@readychek.com" class="rc-text-primaryDark">Support</a> 
            to discuss your requirements.<br>
          </div>
          <div class="rc-box-col-4" />
        </div>
        
        <TitleRow
          v-if="isDriver"
          type="error" :left="LEFT_SIDE" :right="RIGHT_SIDE">Attention Driver!</TitleRow>
    
        <div class="rc-row" v-if="isDriver">
          <div class="rc-box-col-4" />
          <div class="rc-box-col-7 rc-text-value rc-text-small">
            ReadyChek is designed for use by the owner or manager of a business. 
            That being said, we would still like to speak to you and determine if we could 
            start the conversation with your company.<br>
            Please email <a href="mailto:support@readychek.com" class="rc-text-primaryDark">Support</a>
            with your name and any details about your company, and we can work together
            to determine the next steps or provide your managers email address so they 
            can be informed about ReadyChek.
            You can continue with the account creation.
          </div>
          <div class="rc-box-col-4" />
        </div>
        
        <div class="rc-row" v-if="isDriver">
          <div class="rc-box-col-4" />
          <div class="rc-box-col-7">
            <div class="container">
              <KeyEmail
                :mandatory="true"
                :required="isRequired('managersEmail')"
                variable="managersEmail" 
                title="managersEmail"
                :disabled="isDisabled"
                placeholder="Managers Email"
                v-on:value="setValue"/>
            </div>
          </div>
          <div class="rc-box-col-4" />
        </div>
        
        <KeyList 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :mandatory="true"
          :required="isRequired(SignUpFields.FLEET_SIZE)"
          :title="SignUpFields.FLEET_SIZE"
          :disabled="isDisabled" 
          :options="fleetSize"
          :placeholder="CatalogKeys.SIGN.UP.FLEET_SIZE"
          v-on:value="setValue"/>
        
        <SpacerRow />
        
        <!-- Features -->
        <TitleRow :left="LEFT_SIDE" :right="RIGHT_SIDE">{{ C.get(CatalogKeys.SIGN.UP.FEATURES_TITLE) }}</TitleRow>
    
        <!--
        <KeyCheck
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :title="SignUpFields.FEATURE_CHECK_IN"
          styling="rc-background-even"
          :placeholder="CatalogKeys.SIGN.UP.FEATURE_CHECK_IN"
          :disabled="isDisabled" 
          v-on:value="setValue"/>
        <LabelRow 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          styling="rc-text-label-colour rc-text-small rc-background-even"
          label="With this feature drivers are required to answer provincial or territorial COVID questions before starting their day." />
        -->
        
        <KeyCheck
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :title="SignUpFields.FEATURE_HOURS_OF_SERVICE"
          placeholder="Duty Status and Hours of Service"
          :disabled="isDisabled" 
          styling="rc-background-even"
          v-on:value="setValue"/>
        <LabelRow 
          :left="LEFT_SIDE + 1" :right="RIGHT_SIDE"
          styling="rc-text-label-colour rc-text-small"
          label="With this feature drivers have the option of recording their duty status (Driving, Not Driving, Off Duty) each day." />
        
        <KeyCheck
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :title="SignUpFields.FEATURE_INSPECTION"
          :placeholder="CatalogKeys.SIGN.UP.FEATURE_INSPECTION"
          :disabled="isDisabled" 
          styling="rc-background-even"
          v-on:value="setValue"/>
        <LabelRow 
          :left="LEFT_SIDE + 1" :right="RIGHT_SIDE"
          styling="rc-text-label-colour rc-text-small"
          label="With this feature drivers have the option of recording daily pre-trip vehicle inspections." />
        
        <SpacerRow />
    
        <!-- Ads -->
        <TitleRow :left="LEFT_SIDE" :right="RIGHT_SIDE">{{ C.get(CatalogKeys.SIGN.UP.HOWD_YOU_HEAR_ABOUT_US) }}</TitleRow>
    
        <KeyList 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :mandatory="true"
          :required="isRequired(SignUpFields.ADS)"
          :title="SignUpFields.ADS"
          :disabled="isDisabled" 
          :options="referrals"
          placeholder="From a partner?"
          v-on:value="setAdsValue"/>
      
        
        <KeyList 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :mandatory="true"
          :required="isRequired(SignUpFields.ADS)"
          :title="SignUpFields.ADS"
          :disabled="isDisabled" 
          :options="ads"
          :placeholder="CatalogKeys.SIGN.UP.ADS"
          v-on:value="setAdsValue"/>
        
        <KeyValue v-if="isOther"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :title="SignUpFields.OTHER"
          hint="Please provide details on how you found ReadyChek"
          placeholder="Details" 
          v-on:value="setValue"/>
          
        <SpacerRow />
        <!-- Promo Code -->
        <TitleRow :left="LEFT_SIDE" :right="RIGHT_SIDE">{{ C.get(CatalogKeys.SIGN.UP.PROMO_CODE_TITLE) }}</TitleRow>
    
        <LabelRow 
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          styling="rc-text-label-colour rc-text-small"
          label="Use one of our partners promotional codes here to receive a discount" />
        
        <KeyValue
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :title="SignUpFields.CODE"
          :disabled="isDisabled" 
          :placeholder="CatalogKeys.SIGN.UP.CODE" 
          v-on:value="setValue"/>
     
        <SpacerRow />
        
        <TitleRow :left="LEFT_SIDE" :right="RIGHT_SIDE">{{ C.get(CatalogKeys.SIGN.UP.TERMS_TITLE) }}</TitleRow>
        
        <KeyCheck
          :mandatory="true"
          :required="isRequired(SignUpFields.TERMS)"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :title="SignUpFields.TERMS" 
          :disabled="isDisabled" 
          :placeholder="CatalogKeys.SIGN.UP.TERMS_MESSAGE"
          :address="saasLocation()"
          :addressLabel="CatalogKeys.SIGN.UP.TERMS_ADDRESS_LABEL"
          v-on:value="setValue"/>
        
        <SpacerRow />
        
        <LabelRow
          styling="rc-text-medium rc-text-value"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          :centre="true"
          label="Yes I'd like to start my FREE trial!"/>
        
        <!-- Save -->
        <SaveRow 
          :left="4" :right="4"
          :disabled="isDisabled" 
          :label="CatalogKeys.SIGN.UP.BUTTON"
          v-on:buttonPressed="signUp"/>
        
        <SpacerRow />
        
     </Column>
     <Column :width="3"/>
     
    </Row>
    
  </Box>
</template>

<script src="https://www.google.com/recaptcha/api.js?render=6Lc9MgAbAAAAAH_Xbn3ffs0JZIQfrYHa_5bQURg7"></script>

<script>
import { mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';

import ContentUtils from '@/utils/ContentUtils.js';
import WebLogger from "@/domain/logger/WebLogger.js";
import EventUtils from '@/utils/EventUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

import Catalog from "@/domain/session/Catalog.js";
import UserEvents from "@/domain/model/user/UserEvents.js";
import EventsDomain from "@/domain/model/events/EventsDomain.js";

import ErrorRow from "@/components/row/ErrorRow.vue";
import SpacerRow from "@/components/row/SpacerRow.vue";
import SuccessRow from '@/components/row/SuccessRow.vue';
import SaveRow from '@/components/row/SaveRow.vue';
import EmailRow from '@/components/row/EmailRow.vue';
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import LabelRow from '@/components/row/LabelRow.vue';

import KeyCheck from '@/components/input/KeyCheck.vue';
import KeyValue from '@/components/input/KeyValue.vue';
import KeyPhone from '@/components/input/KeyPhone.vue';
import KeyList from '@/components/input/KeyList.vue';
import KeyEmail from '@/components/input/KeyEmail.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'signup',
  components: {
    Box, Row, Column,
    ErrorRow,
    SuccessRow,
    SpacerRow,
    SaveRow,
    EmailRow,
    KeyValue,
    KeyPhone,
    KeyList,
    KeyCheck,
    KeyEmail,
    TitleRow,
    LabelRow,
  },
  data() {
    return {
      isDisabled: false,
      message: null,
      error: null,
      logger: null,
      missing: null,
      
      kvps: {
        'featureCheckIn': false,
        'featureHoursOfService': false,
        'featureInspection': false,
      },
      
      LEFT_SIDE: 0,
      LEFT_SIDE_LABEL: 5,
      RIGHT_SIDE: 0,
      CatalogKeys: Catalog.keys(),
      C: new Catalog(),
      SignUpFields: UserEvents.SignUp.Fields,
      provinces: ContentUtils.PROVINCES(),
      ads: ContentUtils.ADS(),
      referrals: ContentUtils.REFERRALS(),
      fleetSize: ContentUtils.FLEET_SIZE(),
      roles: [
        { '@rid': 'owner',   'name': 'Owner/Operator' },
        { '@rid': 'manager', 'name': 'Manager' },
        { '@rid': 'driver',  'name': 'Driver' },
        { '@rid': 'other',   'name': 'Other' },
      ],
      
      isDriver: false,
      isOther: false,
      isAmerican: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_client',
      'auth_connected',
      'auth_user',
      'domain',
      ]),
    console: () => console,
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.sendWithRecaptcha();
      }
    },
  },
  mounted: function() {
  },
  methods: {
    saasLocation: function() {
      return this.auth_client.cdnHost + "/saas/2022/Software_Agreement_v1.2.pdf"
    },

    setProvinceValue: function(kvp) {
      this.setValue(kvp);
      var provinces = ContentUtils.PROVINCES();
      for (var i = 0; i < provinces.length; i++) {
        var province = provinces[i];
        if (province['@rid'] === kvp.value) {
          this.isAmerican = province['country'] !== "Canada";
        }
      }
    },
    setRoleValue: function(kvp) {
      this.setValue(kvp);
      this.isDriver = kvp.value === "driver";
    },
    setAdsValue: function(kvp) {
      this.setValue(kvp);
      this.isOther = kvp.value === "Other";
    },
    setValue: function(kvp) {
      if (!this.logger) {
        this.logger = new WebLogger(this.console, this.auth_client.debug);
      }
      ContentUtils.set(this.kvps, kvp, this.logger);
      this.missing = null;
    },
    signUp: function () {
      if (this.auth_connected) {
        this.sendWithRecaptcha();
      } else {
        this.$connect();  
      }
    },
    sendWithRecaptcha: function() {
      this.error = null;
      this.success = null;
      this.send();
    },
    requirementsMet: function() {
      this.missing = null;
      var met = ContentUtils.hasValue(this.kvps, this.SignUpFields.FIRST);
      if (!met) {
        this.missing = this.SignUpFields.FIRST;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.LAST);
      if (!met) {
        this.missing = this.SignUpFields.LAST;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.EMAIL);
      if (!met) {
        this.missing = this.SignUpFields.EMAIL;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.PHONE);
      if (!met) {
        this.missing = this.SignUpFields.PHONE;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.COMPANY_NAME);
      if (!met) {
        this.missing = this.SignUpFields.COMPANY_NAME;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.COMPANY_CITY);
      if (!met) {
        this.missing = this.SignUpFields.COMPANY_CITY;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.COMPANY_PROVINCE);
      if (!met) {
        this.missing = this.SignUpFields.COMPANY_PROVINCE;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.ROLE);
      if (!met) {
        this.missing = this.SignUpFields.ROLE;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.SignUpFields.TERMS);
      if (!met) {
        this.missing = this.SignUpFields.TERMS;
        return met;
      }
      if (this.isDriver) {
        met = ContentUtils.hasValue(this.kvps, 'managersEmail');
        if (!met) {
          this.missing = 'managersEmail';
          return met;
        }
      }
      
      return met;
    },
    isRequired: function(key) {
      if (this.missing === key) {
        return true;
      }
      return false;
    },
    send: function(token) {
      this.error = null;
      if (!this.requirementsMet()) {
        return;
      }
      if (this.auth_connected) {
        const requestEvent = UserEvents.SignUp.Request(this.kvps);
        if (token) {
          requestEvent[ConstUtils.GRECAPTCHA.NAME] = token;
        }
        requestEvent['Saas'] = "Software_Agreement_v1.2.pdf";
        
        EventsDomain.initEvent(requestEvent, null, uuid.v1(), this.auth_client.deviceId, this.auth_client.version);
        EventUtils.Send.event(this.$socket, requestEvent);
        this.doneSignUp();
      }
    },
    doneSignUp: function() {
      this.message = "Success!";
      this.isDisabled = true;
      var cookie =  {
          email: this.kvps['email'],
          signUp: true,
      };
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNUP_DONE });      
    }
  }  
}
</script>